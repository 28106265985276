<template>
  <svg viewBox="0 0 20 20">
    <path
      d="M10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20ZM9 9H5V11H9V15H11V11H15V9H11V5H9V9Z"
    />
  </svg>
</template>

<script>
export default {
  name: "PlusInvertIcon",
};
</script>
